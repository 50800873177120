var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "grey lighten-4" },
    [
      _c(
        "v-row",
        { attrs: { justify: "center", align: "end" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "8" } },
            [
              _c("v-select", {
                attrs: {
                  width: "80%",
                  "hide-details": "",
                  items: _vm.tmplNames,
                  label: "Template name",
                  disabled: _vm.tmplNames.length == 0
                },
                model: {
                  value: _vm.tmplName,
                  callback: function($$v) {
                    _vm.tmplName = $$v
                  },
                  expression: "tmplName"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }