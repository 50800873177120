var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-main",
    [
      _c("template-select-toolbar", {
        attrs: { duct: _vm.duct, prjName: _vm.prjName },
        on: { "child-event": _vm.tmplNameEvent }
      }),
      _c("router-view", {
        attrs: { duct: _vm.duct, prjName: _vm.prjName, tmplName: _vm.tmplName }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }